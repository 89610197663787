
import XHR from './libs/XHR.js';

let JSApp=function(){
    this.datas={};
    this.XHR=XHR;

    this.lang='fr';
    this.react={blocks:{}};
    this.visiblock=1;

    this.onready=()=>{};
    let build=()=>{
        build.load();
    };
    build.load=()=>{
        let xhr=new XHR({
			url:'./datas/cv_00.json',
            type:'json'
		});
		xhr.send().then(evt=>{
			// console.log('----evt',evt.target.response);
            this.datas=evt.target.response;
            build.datas();
            this.onready();
		}).catch(e=>console.log('-----err',e));
    };
    build.datas=()=>{
        let icons=this.datas.icons,
        ikonz={};
        for(let i=0,id;i<icons['fr'].length;i++){
            id=icons['fr'][i].id;
            ikonz[id]={
                'fr':icons['fr'][i],
                'en':icons['en'][i]
            }
        }
        this.datas.ikonz=ikonz;
    };

    this.changeLang=function(lang){
        this.react.layout.setState({lang:lang});
    };
    this.registerBlock=function(name,block){
        this.react.blocks[name]=block;
    };
    this.toggleBlocks=function(){
        this.visiblock=!this.visiblock;
        for(let k in this.react.blocks){
            this.react.blocks[k]._block.setVisible(this.visiblock);
        }
    };
    //this.state.JSApp.react.layout

    build();
};

export default new JSApp();
