
let _XHR={};


let XHR=function(params){
    this.xhr = new XMLHttpRequest();

    let _priv={
        url:'/',
        method:'get'
    };
    let build=()=>{
        params=typeof(params)==='object'?params:(typeof(params)==='string'?{url:params}:{});
        if(params.url)this.setUrl(params.url);
        if(params.type)this.setType(params.type);
        if(params.method)this.setMethod(params.method);
        if(params.data)this.setData(params.data);
        if(params.headers)this.setHeaders(params.headers);
    };

    _XHR.proto(this,_priv);

    build();
};
XHR.load=function(url,contentType){
    // let xhr=new XHR
};

// ---- xhr proto with _priv
_XHR.proto=function(tgt,_priv){
    Object.keys(_XHR.proto)
    .forEach(k=> {
        tgt[k]=function(...args){
            return _XHR.proto[k].apply(tgt,[_priv].concat(args))||tgt;
        }.bind(tgt);
    });
};

_XHR.proto.setUrl=function(_priv,url){
    if(typeof(url)==='string'){
        _priv.url=url;
    }
};
_XHR.proto.setData=function(_priv,data){
    if(typeof(data)==='object'){
        _priv.formData=new FormData();
        for(let k in data){
            let c=typeof(data[k])==='object'?
                JSON.stringify(data[k]):data[k];
            _priv.formData.append(k,c);
        }
    }
};
_XHR.proto.setMethod=function(_priv,method){
    if(typeof(method)==='string'){
        _priv.method=method;
    }
};
_XHR.proto.setType=function(_priv,type){
    if(typeof(type)==='string'){
        _priv.type=type;
    }
};
XHR.prototype.setHeaders=function(_priv,headers){
    if(typeof(headers)==='object'){
        _priv.headers=headers;
    }
    return this;
};

_XHR.proto.send=function(_priv,usr,pw){
    // console.log('send');
    return new Promise((then,fail)=>{
        this.xhr.addEventListener('load',(evt)=>then(evt), false);
        this.xhr.addEventListener('error',(evt)=>fail(evt), false);
        this.xhr.addEventListener('abort',(evt)=>fail(evt), false);
        this.xhr.withCredentials = true;
        this.xhr.getResponseHeader("Set-Cookie");
        this.xhr.responseType=_priv.type;


        this.xhr.open(_priv.method,_priv.url,true,usr,pw);
        if(_priv.headers){
            for(let k in _priv.headers){
                this.xhr.setRequestHeader(k+'',_priv.headers[k]+'');
            }
        }
        // console.log('_priv',_priv);
        this.xhr.send();
    });
};
// oReq.open("GET", url, true);
// oReq.responseType = "arraybuffer";
// oReq.onload = function(e) {
//   var arraybuffer = oReq.response; // n'est pas responseText
//   /* ... */
// }
// oReq.send();


// ---- xhr real prototype
XHR.prototype.setType=function(type){
    this.xhr.responseType = type;
    return this;
};

XHR.prototype.on=function(type,callback){
    if(type in _XHR.evts){
        this.xhr.addEventListener(type,_XHR.cbWrap(type,callback), false);
    }else if (type==='event') {
        Object.keys(_XHR.evts)
        .forEach(k=>{
            this.xhr.addEventListener(k,_XHR.cbWrap(k,callback), false);
        });

    }
    return this;
};

// ----------- publics tools



// ----------- privates tools

_XHR.evts={
    "progress":(evt)=>evt,
    "load":(evt)=>evt,
    "error":(evt)=>evt,
    "abort":(evt)=>evt,
};

_XHR.cbWrap=function(type,callback){
    let ewrap=_XHR.evts[type];
    return (evt)=>{
        return {
            type:type,
            evt:evt,
            data:ewrap(evt)
        };
    };
};


export default XHR;
