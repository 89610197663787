import React, { Component } from 'react';


import YCVBlock from './YCVBlock';


class YCVBlockFormation extends Component {
	constructor(props) {
		super(props);
		this.state={
			JSApp:props.JSApp,
			lang:props.lang
		};
		this.idc=0;
		this.state.JSApp.registerBlock('forma',this);
	}

	render(){
		let lang=this.state.lang;
		let _head=this.state.JSApp.datas.formation_title[lang];
		let forma=this.state.JSApp.datas.formation;
		forma=forma.map((f,i)=>{
			return (<div
				className="row ycv-block-grid"
				key={i}
				><div
					className="col-sm-2 col-md-2"
					>{f.year}</div>
				<div
					className="col-sm-10 col-md-10"
					dangerouslySetInnerHTML={{ __html: f[lang] }}
				></div>
			</div>);
		});

		return (<YCVBlock
			_head={_head}
			_body={forma}
			key={this.idc++}
			parent={this}
			/>);
	}
}

export default YCVBlockFormation;
