import React, { Component } from 'react';



import './YCVBlock.scss';

class YCVBlock extends Component {
	constructor(props) {
		super(props);
		this.state={
			_head:props._head||'',
			_body:props._body||''
		};
		this.visible=true;
		this._refs={
			_body:React.createRef()
		};
		if(props.parent){
			props.parent._block=this;
		}

		this.toggle=function(){
			this.setVisible(!this.visible);
		}.bind(this);

		this.setVisible=function(visible){
			this.visible=!!visible;
			console.log(this._refs._body);
			this._refs._body.current.style.display=this.visible?'':'none';
		}.bind(this);

		// this.action=function(visible){
		// }.bind(this);
		// this.state.JSApp.registerBlock('hoobies',this);

	}


	render(){
		return (<div
			className="ycv-block container"
			><div
			className="ycv-block-head col-md-12"
			onClick={this.toggle}
			>{this.state._head}
			</div><div
			className="ycv-block-body col-md-12"
			ref={this._refs._body}
			>{this.state._body}
			</div></div>);
	}
}

export default YCVBlock;
