import React, { Component } from 'react';


import YCVBlock from './YCVBlock';
import YCVBlockHobbies from './YCVBlockHobbies';
import YCVBlockFormation from './YCVBlockFormation';
import YCVBlockSkills from './YCVBlockSkills';
import YCVBlockWorks from './YCVBlockWorks';


class YCVBody extends Component {
	constructor(props) {
		super(props);
		this.state={
			JSApp:props.JSApp,
			lang:props.lang
		};

	}

	render(){
		let perso_data=this.state.JSApp.datas.perso[this.props.lang];
		let perso=(<div className="container ycv-block ycv-block-perso">
			<div className="col-sm-4 col-md-4">
			Gilles Charlet
			<div
			dangerouslySetInnerHTML={{ __html: perso_data }}
			></div>
			</div>
		</div>);
		var blocks={
			test:{
				jsx:(<YCVBlock
					_head='title'
					_body='content'
					// key={0}
					/>)
			},
			skills:{
				jsx:(<YCVBlockSkills
					JSApp={this.state.JSApp}
					lang={this.props.lang}
					// key={1}
					/>)
			},
			works:{
				jsx:(<YCVBlockWorks
					JSApp={this.state.JSApp}
					lang={this.props.lang}
					// key={1}
					/>)
			},
			forma:{
				jsx:(<YCVBlockFormation
					JSApp={this.state.JSApp}
					lang={this.state.lang}
					// key={1}
					/>)
			},
			hobbies:{
				jsx:(<YCVBlockHobbies
					JSApp={this.state.JSApp}
					lang={this.props.lang}
					// key={2}
					/>)
			}
		};
		return (<div
			>
			{perso}
			{blocks.skills.jsx}
			{blocks.works.jsx}
			{blocks.forma.jsx}
			{blocks.hobbies.jsx}
			</div>);
	}
}

export default YCVBody;
