import React, { Component } from 'react';


import YCVBlock from './YCVBlock';


class YCVBlockHobbies extends Component {
	constructor(props) {
		super(props);
		this.state={
			JSApp:props.JSApp,
			lang:props.lang
		};
		this.idc=0;
		this.state.JSApp.registerBlock('hoobies',this);
	}

	render(){
		let _head=this.state.JSApp.datas.hobbies_title[this.props.lang];
		let _body=this.state.JSApp.datas.hobbies[this.props.lang];
		return (<YCVBlock
			_head={<div dangerouslySetInnerHTML={{ __html: _head }}></div>}
			_body={<div dangerouslySetInnerHTML={{ __html: _body }}></div>}
			key={this.idc++}
			parent={this}
			/>);
	}
}

export default YCVBlockHobbies;
