import React, { Component } from 'react';



import './YCVHead.scss';

class YCVHead extends Component {
	constructor(props) {
		super(props);
		var _this=this;
		this.state={
			JSApp:props.JSApp,
			lang:props.lang,
			visiblock:1
		};
		this.props=props;
		this.state.JSApp.react.head=this;
	}
	changeLang(lang){
		// console.log('changeLang',lang);
		this.state.JSApp.changeLang(lang);
	}
	toggleBlocks(){
		let react=this.state.JSApp.react;
		let vb = this.state.visiblock;
		this.setState({
			visiblock:!vb
		});
        for(let k in react.blocks){
            react.blocks[k]._block.setVisible(!vb);
        }
	}

	render(){
		let _this=this;
		let props=this.props;
		let ikonz=this.state.JSApp.datas.ikonz;
		let lang=this.props.lang;
		let flagik=(_lang)=>{
			let img=(<img src={'imgs/flag-'+_lang+'.svg'} />);
			return (<span
				className={'enfr-'+(_lang===lang?'on':'off')}
				onClick={()=>this.changeLang(_lang)}
				>{img}</span>);
		};


		let ikz={};
		ikz.enfr={
			jsx:(<span
				className='ycv-ikonz ycv-ikonz-enfr'
				title={ikonz.enfr[lang].title}>
				{flagik('en')}
				{flagik('fr')}
			</span>)
		};
		//
		ikz.pdf={
			jsx:(<span
				className='ycv-ikonz ycv-ikonz-pdf'
				title={ikonz.pdf[lang].title}
				onClick={function(){window.open('get_pdf.php?lang='+props.lang,'_blank');}}
				>
				PDF
			</span>)
		};
		ikz.showhide={
			jsx:(<span
				className='ycv-ikonz ycv-ikonz-showhide'
				title={ikonz.showhide[lang].title}
				onClick={function(){_this.toggleBlocks()}}
			>
				<span
				className={"glyphicon glyphicon-eye-"+(this.state.visiblock?'open':'close')}
				></span>
			</span>)
		};


		return (<div
			className="ycv-head"
			>
			{ikz.enfr.jsx}
			{ikz.pdf.jsx}
			{ikz.showhide.jsx}
			<div className='ycv-tags'></div>
		</div>);
	}
}

export default YCVHead;
