import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import './components/ycv-block-grid.scss';


// import TestComponent from './components/TestComponent';
import JSApp from './js/JSApp.js';
import YCVLayout from './components/YCVLayout';
//YCVLayout
class App extends Component {
    constructor(props) {
		super(props);
		this.state={
            ready:0
        };
        JSApp.onready=()=>{
            this.setState({ready:1});
        };
        // console.log('JSApp',JSApp);
	}
	render() {
		return this.state.ready?(

            <YCVLayout JSApp={JSApp}/>
            // <div>{"new App"}
			// <TestComponent />
			// </div>
		):(<div>loading ...</div>);
	}
}

export default App;

/*
import React, { Component } from 'react';
import './App.css';
import {Y_} from 'Y_/Y_.js';
import seeds from './data/seeds.json';
import TestComponent from './components/TestComponent';
class App extends Component {
	constructor(props) {
		super(props);
		this.state={};
	}
	render() {
		return (
			<div>{"new App"}
			<TestComponent />
			</div>
		);
	}
}

export default App;

*/
