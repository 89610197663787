import React, { Component  } from 'react';

import YCVBody from './YCVBody';
import YCVHead from './YCVHead';

import './YCVLayout.scss';

class YCVLayout extends Component {
	constructor(props) {
		super(props);
		this.state={
			JSApp:props.JSApp,
			lang:'fr'
		};
		this.props=props;
		// console.log('this.xhr',this.xhr);
		// console.log('datas',props.JSApp.datas);
		this._refs={
			_root:React.createRef(),
			_head:React.createRef(),
			_body:React.createRef()
		};
		this.state.JSApp.react.blocks={};
		this.state.JSApp.react.layout=this;


		this.getDoms=function(){
			let doms={};
			for(let k in this._refs){
				doms[k.substr(1)]=this._refs[k].current;
			}
			return doms;
		}.bind(this);

		this.updateSize=function(){
			let doms=this.getDoms();
			// console.log(doms);
			let width=doms.root.offsetWidth;
			let height=doms.root.offsetHeight;
			let hh = doms.head.offsetHeight;
			height+=-hh;
			doms.head.style.width=width+'px';
			doms.body.style.width=width+'px';
			doms.body.style.top=hh+'px';
			doms.body.style.height=height+'px';
			// let body = _this._refs._body.current;

			// body.style.height=body.parentNode.offsetHeight+'px';
		}.bind(this);
	}
	componentDidMount(){
		window.addEventListener('resize',this.updateSize);
		this.updateSize();
	}
	componentWillUnmount(){
		window.removeEventListener('resize',this.updateSize);
	}
	componentDidUpdate(){
	}
	// componentWillUpdate(){
	// }
	render(){
		var _this=this;
		// {Object.keys(blocks).map(k=>blocks[k].jsx)}
		return (<div
			className="ycv-layout"
			ref={this._refs._root}
			>
			<iframe
			   className="ycv-layout-back"
			   src="cv_back_00.html"
				></iframe>
			<div
				 className="ycv-layout-front"
				 >
			<div
				 className="ycv-layout-head"
				 ref={this._refs._head}
				 >
				<YCVHead
				JSApp={this.state.JSApp}
				lang={this.state.lang}
				/>
			</div>
			<div
				className="ycv-layout-body"
				ref={this._refs._body}
				>
				<YCVBody
				JSApp={this.state.JSApp}
				lang={this.state.lang}
				/>
				</div>
				</div>
		</div>);
	}
}

export default YCVLayout;
