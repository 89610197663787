import React, { Component } from 'react';


import YCVBlock from './YCVBlock';


class YCVBlockWorks extends Component {
	constructor(props) {
		super(props);
		this.state={
			JSApp:props.JSApp,
			lang:props.lang
		};
		this.props=props;
		this.idc=0;
		this.state.JSApp.registerBlock('works',this);
	}

	renderWork(work,i){
		let lang=this.props.lang;
		let img = work.img||'./imgs/blank.png';
		if(img){
			//img='cv_back_01/'+img;
		}
		return (<div className="row"
			key={i}
			>
			<div className="col-sm-2 hidden-xs ycv_imgc_md">
				<img src={img}/>
			</div>
			<div className="visible-xs ycv_div_xs">
				<div className="ycv_imgc_xs" style={{backgroundImage:'url(' + img + ')'}}>
				</div>
			</div>
			<div
				className="col-sm-10"
				dangerouslySetInnerHTML={{ __html: work[lang] }}
			></div>
		</div>);
	};
	renderYear(year,i){
		let lang=this.props.lang;
		let list=year.list.map(this.renderWork.bind(this));
		return (<div
			className="row ycv-block-grid"
			key={i}
			>
			<div
				className="col-sm-2 col-md-2"
				>{year.year}
			</div>
			<div
				className="col-sm-10 col-md-10"
				>
				{list}
			</div>
		</div>);
	};
	render(){
		let lang=this.props.lang;
		let _head=this.state.JSApp.datas.works_title[lang];
		_head=(<div
			dangerouslySetInnerHTML={{ __html: _head }}
			></div>);
		let works=this.state.JSApp.datas.works;
		works=works.map(this.renderYear.bind(this));

		return (<YCVBlock
			_head={_head}
			_body={works}
			key={this.idc++}
			parent={this}
			/>);
	}
}

export default YCVBlockWorks;
